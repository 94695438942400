import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { OpenContentService } from 'share/src/service/open-content.service';
import { PlaybackToken } from '@swan/lib/content';
import { Content } from '@swan/lib/content';

export interface ContentData {
  playback: PlaybackToken|null, 
  content:Content|null
}

@Injectable({
  providedIn: 'root'
})
export class ContentResolver implements Resolve<ContentData> {

  constructor(
    private readonly _contentService: OpenContentService,
    private _router: Router
  ){}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ContentData>
  {
    const contentId = route.paramMap.get('contentId');
  
    if (contentId) {
      return forkJoin({
        playback: this._contentService.getPlaybackToken(contentId),
        content: this._contentService.getContentData(contentId)
      });
    }
    else {
      this._router.navigate(['/']);
      return of({playback: null, content: null});
    }
  }
}
