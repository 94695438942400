<div class="bg-black h-full w-full flex">
    <mat-card class="bg-white m-auto rounded-lg max-w-8 ">
        <mat-card-title class="mb-8">
            <img src="/assets/icons/logo.svg" alt="" class="h-8">
        </mat-card-title>
        <mat-card-content class="text-center">
            {{ "SHARED_PROFILE_INFO" | transloco }}
        </mat-card-content>
        <mat-card-actions>
            <div class="flex flex-col">
                <button mat-flat-button class="bg-black text-white rounded-full">{{ "Download App" | transloco
                    }}</button>
            </div>
        </mat-card-actions>
    </mat-card>

</div>