import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(
    private route:ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe((data:Data) => {
      const profileData = data["data"];
      console.log('comp',profileData);
    })
  }
}
