import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Data, Route } from '@angular/router';
import { Content, PlaybackToken } from '@swan/lib/content';
import { ContentData } from '../content.resolver';
import { Observable, Subject, lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-content-player',
  templateUrl: './content-player.component.html',
  styleUrls: ['./content-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentPlayerComponent implements OnInit {
  @ViewChild('muxVideo') readonly muxVideo: ElementRef<HTMLVideoElement> = null as never;
  @ViewChild('storelink') readonly storelink: ElementRef<HTMLAnchorElement> = null as never;

  playback!: PlaybackToken;
  content!: Content;
  id : string = '';
  token : string = '';  

  constructor(
    private route: ActivatedRoute,
    private injector: Injector,
    private ref: ChangeDetectorRef
    ) {}

  ngOnInit(): void {
    this.route.data.subscribe((data:Data) => {
      const contendData = data["data"] as ContentData;
      console.log('comp',contendData);
      if (contendData.playback) {
        this.playback = contendData.playback;
        this.id = this.playback.videoSrc.split("?")[0]
        this.token = this.playback.videoSrc.split("?")[1].substring(6);
      }
      if(contendData.content){
        this.content = contendData.content;
      }
    }
    )
  }

  ngAfterViewInit(): void {
    if(this.playback){
      this.initMuxPlayer();    
    }
    this.ref.markForCheck();
  }

  initMuxPlayer() {
    this.muxVideo.nativeElement.setAttribute('stream-type', 'on-demand');
    //this.muxVideo.nativeElement.setAttribute('playback-id', this.playback.videoSrc);
    this.muxVideo.nativeElement.setAttribute('playback-id', this.id);
    this.muxVideo.nativeElement.setAttribute('playback-token', this.token);
    this.muxVideo.nativeElement.setAttribute('storyboard-token', this.token);
    this.muxVideo.nativeElement.setAttribute('thumbnail-token', this.token);
    this.muxVideo.nativeElement.setAttribute('metadata-video-title', this.content.info.name);
    this.muxVideo.nativeElement.setAttribute('metadata-viewer-user-id', "anonymous User");
    //this.muxVideo.nativeElement.setAttribute('src', this.playback.videoSrc);
    //this.muxVideo.nativeElement.parentElement?.setAttribute('data-setup', `{timelineHoverPreviewsUrl: ${this.playback.storyBoard}}`);
    
  }

  public createStoreLink() {
    var userAgent = navigator.userAgent;

    if (userAgent.includes("Android")) {
        return "https://play.google.com/store/apps/details?id=com.swanmirror.android";
    } else if (userAgent.includes("iPhone")) {
        return "https://apps.apple.com/de/app/swan-beauty/id1641229945";
    }
    return "https://www.swanmirror.com/de#swan-app"
  }

}
