<div id="swan-product-video" class="h-full w-full bg-black">
    <!-- Player Theme -->
    <template id="swan-theme" *ngIf="content">
        <media-controller class="h-full w-full" breakpoints="sm:384 md:576">
            <div slot="top-chrome" style="color:white; width:100%">
                <!-- optional place for profile -->
                <div
                    style="display: flex; flex-direction: column; flex-grow: 1;padding:2em 1em; font-family: 'Bossa-Medium'; gap: 1em;    background: linear-gradient(180deg, var(--media-control-background), transparent);">
                    <div style="display:flex; flex-direction: row; line-height: 1.2rem; ">
                        <img [src]="'https://swan.dev.cloud.yukawa.de/api/profile-service/image/download?imageId='+content.author.imageId"
                            alt="SWAN Logo" style="border-radius: 50%; width: 50px; height: 50px; margin:0 1em;">
                        <div style="">
                            <div style="font-weight: bold">{{ content.author.displayName }}</div>
                            <div><span style="font-size: .8em;">{{'@'+content.author.nickname}}</span> <a [href]="createStoreLink()" target="_blank" style="color:white;font-size: .8em">{{
                                    "FOLLOW" |
                                    transloco }}</a></div>
                        </div>
                    </div>
                    <div style="margin-bottom: 2em">
                        {{ content.info.name}}
                    </div>
                </div>
            </div>

            <slot name="media" slot="media"></slot>

            <media-loading-indicator slot="centered-chrome" noautohide></media-loading-indicator>
            <media-play-button slot="centered-chrome" class="rounded-lg"
                style="border-radius: 40px; backdrop-filter: blur(3px);">
                <span slot="play" style="width: 50px; height: 24px; ">
                    <svg viewBox="0 0 24 24" style="width:100%; height:100%">
                        <path d="M18.3599 12L8 19.9691V4.03086L18.3599 12Z" stroke="currentColor" stroke-width="2"
                            fill="none" />
                    </svg>
                </span>
                <span slot="pause" style="width: 50px; height: 24px;">
                    <svg style="color:white" viewBox="0 0 24 24" style="width:100%; height:100%">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 4H7V20H9V4ZM17 4H15V20H17V4Z"
                            fill="currentColor" />
                    </svg>
                </span>
            </media-play-button>
            <media-control-bar>

            </media-control-bar>
            <media-control-bar>
                <media-time-range class="bg-transparent"></media-time-range>
            </media-control-bar>
            <media-control-bar>
                <media-mute-button></media-mute-button>
                <media-volume-range></media-volume-range>
                <span class="spacer" style="flex-grow:1; background-color: var(--media-control-background);"></span>
                <media-time-display showduration remaining></media-time-display>
                <media-captions-menu hidden anchor="auto"></media-captions-menu>
                <media-captions-menu-button></media-captions-menu-button>
                <!--<media-pip-button></media-pip-button>-->
                <!--<media-fullscreen-button></media-fullscreen-button>-->
            </media-control-bar>
            <div style="width:100%;height:.5em; background-color: var(--media-control-background);"></div>
        </media-controller>
    </template>
    <!-- mux player impl -->
    <div class="mux-container h-full w-full flex p-8" *ngIf="playback; else liveShowTemplate">
        <mux-player #muxVideo class="aspect-video" crossOrigin theme="swan-theme">
        </mux-player>
    </div>
    <ng-template #liveShowTemplate>
        <div class="h-full w-full flex flex-col items-center justify-items-end text-white">

            <div
                style="display: flex; flex-direction: column; flex-grow: 1;padding:1em; font-family: 'Bossa-Medium'; gap: 1em;    background: linear-gradient(0, var(--media-control-background), transparent);">
                <div class="-z-10 object-contain">
                    <img [src]="content.thumbnail" alt="content.info.name">
                </div>
                <div style="display:flex; flex-direction: row; line-height: 1.2rem; ">
                    <img src="/assets/icons/swan-icon.svg" alt="SWAN Logo"
                        style="border-radius: 50%; width: 50px; height: 50px; margin:0 1em;">
                    <div style="">
                        <div style="font-weight: bold">{{ content.author.displayName }}</div>
                        <div><a [href]="createStoreLink()" target="_blank" style="color:white;font-size: .8em">{{
                                "FOLLOW" |
                                transloco }}</a></div>
                    </div>
                </div>
                <div>
                    {{ content.info.name}}
                </div>
                <div *ngIf="content.liveStream" class="mb-2">
                    {{"Live Stream will start" | transloco }} {{ content.liveStream.scheduledTime | date: 'medium' }}
                </div>
            </div>
        </div>
    </ng-template>

</div>