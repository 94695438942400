import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  getStoreLink():string{
    var userAgent = navigator.userAgent;

    if (userAgent.includes("Android")) {
        return "https://play.google.com/store/apps/details?id=com.swanmirror.android";
    } else if (userAgent.includes("iPhone")) {
        return "https://apps.apple.com/de/app/swan-beauty/id1641229945";
    }
    return "https://www.swanmirror.com/de#swan-app"
  }
}
