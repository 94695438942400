import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './comp/shared/layout/layout.component';
import { ContentPlayerComponent } from './comp/content/content-player/content-player.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslocoCoreModule } from '@swan/lib/config';
import { getUserLanguage } from '@yukawa/chain-base-angular-comp/shared';
import { environment } from '../environments/environment';
import { APP_ENVIRONMENT_TOKEN, setAppInjector, setEnvironment } from '@yukawa/chain-base-angular-client';
import { HttpClientModule } from '@angular/common/http';
import { ContentModule } from '@swan/lib/content';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IconsModule } from './core/icons/icons.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ProfileComponent } from './comp/profile/profile.component';
import { MatCardModule } from '@angular/material/card';


setEnvironment(environment);

const availableLanguages = [
    {
        id   : 'en',
        label: 'English',
    },
    {
        id   : 'de',
        label: 'Deutsch',
    },
];

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    ContentPlayerComponent,
    ProfileComponent
  ],
  imports: [
    CommonModule,
    IconsModule,
    BrowserAnimationsModule,
    ContentModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,

    TranslocoCoreModule.forRoot({
      availableLangs      : availableLanguages,
      defaultLang         : getUserLanguage(availableLanguages.map(lang => lang.id), availableLanguages[0].id),
      fallbackLang        : 'en',
      reRenderOnLangChange: true,
      prodMode            : environment.production,
    }),

    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    MatToolbarModule,
  ],
  providers: [
    {
      provide : APP_ENVIRONMENT_TOKEN,
      useValue: environment,
  },
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 

  constructor(injector: Injector)
  {
      setAppInjector(injector);
  }
}
