import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentResolver } from './comp/content/content.resolver';
import { ContentPlayerComponent } from './comp/content/content-player/content-player.component';
import { LayoutComponent } from './comp/shared/layout/layout.component';
import { ProfileComponent } from './comp/profile/profile.component';
import { ProfileResolver } from './comp/profile/profile.resolver';


const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children:[
      {
        path: "share/content/:contentId",
        component: ContentPlayerComponent,
        resolve: {
          data: ContentResolver
        }, 
      },
      {
        path: "share/profile/:nickname",
        component: ProfileComponent, 
      }
    ]
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
